@font-face {
  font-family: 'poppins';
  src: url('./Assests/Font/poppins/Poppins-Regular.otf');
}

* {
  font-family: 'poppins';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fs-8 {
  font-size: 8px;
}

.w-fitContent {
  width: fit-content;
}

.ActiveBtn {
  background: #2CC82C14;
  color: #2CC82C;
}

.PauseBtn {
  background: #E3526E14;
  color: #E3526E;
}

.ant-table .ant-table-container table thead.ant-table-thead .ant-table-cell {
  color: #888FA6
}


.ant-table .ant-table-container table tbody.ant-table-tbody .table-row-light .ant-table-cell {
  background-color: #fff;
}

.ant-table .ant-table-container table tbody.ant-table-tbody .table-row-dark .ant-table-cell {
  background-color: #F8F9F9;
}

.ant-layout-sider-collapsed .ant-menu-item {
  padding: 0;
  background-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-pink {
  color: #E3526E !important
}

.bg-pink {
  background-color: #E3526E !important
}

.feed {

  height: 100vh;
  padding: 10px;
}

.feed>button {
  margin: 10px;
}


.Login {
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;

}


/* .ant-menu-item.ant-menu-item-selected { */
.ant-menu-item,
.ant-menu-submenu,
.ant-menu-item.ant-menu-item-active {
  background-color: #F4F6F6;
  color: #E3526E !important;
  position: relative;
  overflow: visible;
}

.ant-menu-item.ant-menu-item-active::before {
  content: '';
  position: absolute;
  top: -2px;
  /* Adjust top distance */
  right: -1px;
  /* Adjust right distance */
  width: 10px;
  /* Adjust dot size */
  height: 10px;
  /* Adjust dot size */
  background-color: #E3526E;
  /* Color of the dot */
  border-radius: 50%;
  /* Make it round */
}

.ant-menu-sub {
  margin-left: 25px;
}

.ant-menu-title-content {
  color: #E3526E !important;
}

.ant-menu-submenu .ant-menu-item {
  background-color: #e3e6e6;

}

.ant-menu-item-icon {
  padding: 10px;
  background-color: rgb(227, 82, 110, 0.15);
  color: #E3526E;
  border-radius: 25%;
}

.video-upload-button {
  border: 1px solid #E3526E !important;
}

.video-upload-button p {
  color: #E3526E;
  font-weight: 600;
  font-size: clamp(12px, 4vw, 24px);
  font-family: 'poppins';
}

.video-thumbnail-container {
  position: relative;
  display: inline-block;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
  max-height: 15vw
}

/* .video-thumbnail img {
  width: 100%;
  height: auto;
} */


.uploaded-image-container {
  height: 200px;
  /* Set a fixed height for the container */
  overflow: hidden;
  /* Ensure that images do not overflow the container */
}

.uploaded-image {
  width: 100%;
  /* Set the width of the image to 100% of its container */
  height: 100%;
  /* Allow the height to adjust proportionally based on the width */
  object-fit: cover;
  /* Ensure that the entire image is visible within its container */
}


.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* backdrop overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-thumbnail:hover .backdrop {
  opacity: 1;
}

.icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons>* {
  margin: 5px;
  color: #fff;
  /* icon color */
  cursor: pointer;
}

.icons>*:hover {
  color: #ff0000;
  /* hover color */
}


.upload-container {
  position: relative;
}

.file-container {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.file-name {
  margin: 0;
}

/* Hide input label when file is selected */
input[type="file"] {
  display: none;
}

.text-feild {
  border: 1px solid #CDD1E0;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.whitespinner>div {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.whitespinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.whitespinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}
