.users {
  padding: 20px;
}

.user-controls {
  margin-bottom: 20px;
}

.user-table {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-table {
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  background-color: #f0f2f5;
  font-weight: 600;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-tbody > tr:hover > td {
  background-color: #f9fafb;
}

.user-controls > .ant-input {
  width: 200px;
}

.user-controls > .ant-btn {
  margin-left: 10px;
}
