body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-modal .ant-modal-content {
  padding: 33px 56px;

  width: 450px;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-input-outlined {
  border-radius: 12px;
  padding: 15px;
}

.modal-button {
  font-size: 14px !important;
}

button.delete-button {
  background-color: #E3526E;
  width: 100%;
  padding: 11px;
  margin-top: 12px;
}


.delete-modal p {
  font-size: 18px;
  font-size: 600;
}

button.delete-button p {
  color: #ffffff;

  font-size: 14px;
  font-family: 'poppins';
}

.admin-text {
  font-size: 18px;
  font-weight: 700;
}

.admin-profile {
  background-color: #F4F6F6;
  border-radius: 8px;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-menu-inline .ant-menu-item {
  padding: 27px 16px !important;
  margin-bottom: 15px;
}

.admin-image img {
  border-radius: 8px;
}

.admin-videos {
  font-size: 24px;
  font-weight: 700;

}

:where(.css-dev-only-do-not-override-djtmh8).ant-layout .ant-layout-sider-trigger {
  display: none;
}


.signup-divider {
  background: #959595;
  height: 1px;
  width: 220px;
}

.login-button {
  background-color: #E3526E;
  color: #FFFFFF;

}

.admin-text {
  font-size: 20px;
  color: #E3526E;
  font-weight: bold;
}

.enterprise-login_heading {
  font-size: 20px;
}

.young-image-section img {
  border-radius: 12px;
}

.Label-form-section label {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;

}